import getConfig from "next/config";
import qs from "qs";

import { i18n } from "@lib/i18n";

import { getIsProductionMode } from "@lib/getIsProductionMode";

import { CLIENT_COUNTRY_HEADER_KEY } from "@shared/constants";

const { publicRuntimeConfig } = getConfig();

const { COUNTRY, BRAND } = publicRuntimeConfig;

export const createDealApi = axios => ({
  axios,

  getAppData(language = i18n.language) {
    return axios.get(
      `/data/app-data?brand=${BRAND}&country=${COUNTRY}&language=${language}`
    );
  },

  updateBrandIdData(fields) {
    return axios.post("/brandid/updatedata", {
      fields,
    });
  },

  createNewDealFromExisting(leadToken, businessModel) {
    return axios.post("/brandid/create-new-deal-from-existing", {
      leadToken,
      businessModel,
    });
  },

  logoutSession() {
    return axios.post("/brandid/logout");
  },

  updateSteps(token, lead, financeGateway, deal) {
    return axios.post(`/leads/${token}/update-steps`, {
      lead,
      deal,
      financeGateway,
    });
  },

  initMultiOrder(token) {
    return axios.get(`/leads/${token}/init-multi-order`);
  },

  updateLastStepCode(token, leadState) {
    return axios.post(`/leads/${token}/update-last-step-code`, leadState);
  },

  getUserDeals(config) {
    return axios.get(`/user/deals`, config);
  },

  getUserOrders(config) {
    return axios.get(`/user/orders`, config);
  },

  getUserInfo(config) {
    return axios.get(`/user/info`, config);
  },

  getDeal(token, options) {
    // We need to add timestamps parameter in order to avoid Chrome caching
    return axios.get(`/leads/${token}?timestamp=${Date.now()}`, options);
  },

  sendDocumentsByEmail(documents) {
    return axios.post(`/mail/send-docs`, documents);
  },

  sendConfigurationByEmail(configuration) {
    return axios.post(`/mail/send-configuration`, configuration);
  },

  purgeCurrentSession() {
    return axios.delete("/session");
  },

  updateIsSelectionConfirmedSession(isSelectionConfirmed) {
    return axios.patch("/session", {
      isSelectionConfirmed,
    });
  },

  getParkopolyAvailableSlots(body, params) {
    return axios.post("/parkopoly/available-slots", body, { params });
  },

  getIsParkopolyHomeTestDriveAvailable(body) {
    return axios.post("/parkopoly/home-test-drive-available", body);
  },

  updateLastCtaToSession(lastCta) {
    return axios.patch("/session", {
      lastCta,
    });
  },

  updateJourneySession(journey, config) {
    return axios.patch("/session", { journey }, config);
  },

  fetchFree2moveVehicles() {
    return axios.get("/free2move/vehicles");
  },

  fetchFree2moveVehicle(id) {
    return axios.get(`/free2move/vehicles/${id}`);
  },

  getInitialTestDriveData(language = i18n.language) {
    if (!language) {
      throw new Error(
        `'language' parameter is required instead got '${language}'`
      );
    }

    return axios.get(
      `/test-drive/all?country=${COUNTRY}&language=${language}&brand=${BRAND}`
    );
  },

  getTestDriveTypes(language = i18n.language) {
    if (!language) {
      throw new Error(
        `'language' parameter is required instead got '${language}'`
      );
    }

    return axios.get(
      `/test-drive/type/list?country=${COUNTRY}&language=${language}&brand=${BRAND}`
    );
  },

  getTestDriveLocationBySlug(slug, language = i18n.language) {
    if (!language) {
      throw new Error(
        `'language' parameter is required instead got '${language}'`
      );
    }

    return axios.get(
      `/test-drive/location?country=${COUNTRY}&language=${language}&brand=${BRAND}`,
      { params: { slug } }
    );
  },

  getTestDriveExclusionDates(testDriveType, language = i18n.language) {
    if (!language) {
      throw new Error(
        `'language' parameter is required instead got '${language}'`
      );
    }

    return axios.get(
      `/test-drive/exclusion-dates?country=${COUNTRY}&language=${language}&brand=${BRAND}&testDriveType=${testDriveType}`
    );
  },

  updateSession(data, config) {
    return axios.patch(`/session`, data, config);
  },

  finalizeOrder(token, cashPromoDepositNone) {
    return axios.get(
      `/ep/${token}/finalize?cashPromoDepositNone=${
        cashPromoDepositNone ? "true" : "false"
      }`
    );
  },

  getDeliveryPoints(language = i18n.language) {
    if (!language) {
      throw new Error(
        `'language' parameter is required instead got '${language}'`
      );
    }

    return axios.get(
      `/delivery/point/list/?country=${COUNTRY}&language=${language}&brand=${BRAND}`
    );
  },

  fetchFinanceRedirect(token, country) {
    return axios.get(`/leads/finance-checkout/${token}/redirect`, {
      headers: {
        [CLIENT_COUNTRY_HEADER_KEY]: country,
      },
    });
  },

  createFinancingBankOffer(token) {
    return axios.get(
      `/leads/finance-checkout/${token}/create-financing-bank-offer`
    );
  },

  sendSlackNotification(data, title) {
    const isProduction = getIsProductionMode();

    if (!isProduction) {
      console.warn(`❗ Sending of Slack notification prevented`, {
        title,
        data,
      });

      return;
    }

    return axios.post("/notify", { data, title });
  },

  updateCsrfToken(importsSecret) {
    return axios.get(`/csv/update-token`, {
      headers: { "x-secret": importsSecret },
    });
  },

  downloadFile(path, importsSecret) {
    return axios.get(`/csv?path=${path}`, {
      headers: { "x-secret": importsSecret },
    });
  },

  validateCSV(importsSecret, payload) {
    return axios.post("/csv/validate", payload, {
      headers: { "x-secret": importsSecret },
    });
  },

  removeFile(path, importsSecret, language = i18n.language) {
    return axios.delete(
      `/csv?path=${path}&brand=${BRAND}&language=${language}`,
      {
        headers: { "x-secret": importsSecret },
      }
    );
  },

  getCurrentFilesList(country, importsSecret, language = i18n.language) {
    return axios.get(
      `/csv/current-files?country=${country}&brand=${BRAND}&language=${language}`,
      {
        headers: { "x-secret": importsSecret },
      }
    );
  },

  getStagedFilesList(country, importsSecret, language = i18n.language) {
    return axios.get(
      `/csv/staged-files?country=${country}&brand=${BRAND}&language=${language}`,
      {
        headers: { "x-secret": importsSecret },
      }
    );
  },

  getUploadHistory(country, startDate, endDate, importsSecret) {
    const query = qs.stringify(
      { country, startDate, endDate },
      { addQueryPrefix: true }
    );

    return axios.get("/csv/history-files" + query, {
      headers: { "x-secret": importsSecret },
    });
  },

  triggerImports(country, importsSecret, language = i18n.language) {
    if (!language) {
      throw new Error(
        `'language' parameter is required instead got '${language}'`
      );
    }

    return axios.post(
      `/csv/run-imports?country=${country}&brand=${BRAND}&language=${language}`,
      null,
      {
        headers: { "x-secret": importsSecret },
        timeout: 120000,
      }
    );
  },

  uploadImportFile(country, files, importsSecret, language = i18n.language) {
    const formData = new FormData();

    files.map(file => {
      formData.append("files", file);
    });

    return axios.post(
      `/csv?country=${country}&brand=${BRAND}&language=${language}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          "x-secret": importsSecret,
        },
      }
    );
  },

  getAssetsList(country, importsSecret, language = i18n.language) {
    return axios.get(
      `/assets?country=${country}&brand=${BRAND}&language=${language}`,
      {
        headers: { "x-secret": importsSecret },
      }
    );
  },

  uploadAsset(country, item, file, importsSecret, language = i18n.language) {
    const formData = new FormData();
    formData.append("file", file);
    for (const key in item) {
      if (Object.prototype.hasOwnProperty.call(item, key)) {
        formData.append(key, item[key]);
      }
    }

    return axios.post(
      `/assets?country=${country}&brand=${BRAND}&language=${language}`,
      formData,
      {
        headers: {
          "x-secret": importsSecret,
          "Content-Type": "multipart/form-data",
        },
      }
    );
  },

  deleteAsset(country, _id, importsSecret, language = i18n.language) {
    return axios.delete(
      `/assets/${_id}?country=${country}&brand=${BRAND}&language=${language}`,
      {
        headers: { "x-secret": importsSecret },
      }
    );
  },

  getMopRecord(brand, country, mopId) {
    return axios.get(`/__testing__/mop`, {
      params: {
        brand,
        country,
        mopId,
      },
    });
  },

  getMopData(brand, country, mopId) {
    return axios.get(`/mop`, {
      params: {
        brand,
        country,
        mopId,
      },
    });
  },

  fetchAccessoriesIncrease(ctx) {
    return axios.post(`/finance/accessories-increase`, ctx);
  },

  fetchFinanceSimulation(ctx) {
    return axios.post(`/finance/simulation`, ctx);
  },

  fetchSantanderSimulation(ctx, simulationCode = null) {
    if (simulationCode) {
      return axios.put(`/finance/scf/simulation/${simulationCode}`, ctx);
    }

    return axios.post(`/finance/scf/simulation`, ctx);
  },

  fetchSantanderFinancing(simulationCode, mopId) {
    return axios.post(`/finance/scf/financing`, { simulationCode, mopId });
  },

  postValidatePromoCode(options) {
    return axios.post(`/promo-code/validate`, options);
  },

  removePromoCodeFromSession(options) {
    return axios.get(`/promo-code/remove`, options);
  },

  getMyFilesStatus(mopId) {
    return axios.get(`/my-files/${mopId}/status`);
  },

  getMyFilesDocuments(mopId) {
    return axios.get(`/my-files/${mopId}/documents`);
  },

  postMyFilesDocument(mopId, files) {
    return axios.post(`/my-files/${mopId}/document`, files, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  deleteMyFilesFile(mopId, externalReference) {
    return axios.delete(`/my-files/${mopId}/document/${externalReference}`);
  },

  getMyFilesFile(mopId, externalReference) {
    return axios.get(`/my-files/${mopId}/file/${externalReference}`);
  },

  postCheckEmail(email) {
    return axios.post(`/brandid/check-email`, { email });
  },

  postCreateForeignAccount(payload) {
    return axios.post(`/brandid/create-foreign-account`, payload);
  },
});
